import React from "react";

import { StyledA, StyledImage } from "./styled";

const Button = ({
  title = "",
  styles = {},
  href = "",
  imageSrc = "",
  imageStyles = {},
  customImagesSrc = [],
  onClick,
}) => {
  return (
    <StyledA styles={styles} href={href} target="_blank" onClick={onClick}>
      {title && <span>{title}</span>}
      {(imageSrc && !!imageStyles.order && !customImagesSrc.length && (
        <StyledImage styles={imageStyles} src={imageSrc} />
      )) || <></>}
      {(customImagesSrc.length &&
        customImagesSrc.map((src) => {
          return <StyledImage styles={imageStyles} src={src} />;
        })) || <></>}
    </StyledA>
  );
};

export default Button;
